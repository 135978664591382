import React from "react"
import Layout from '../components/layout'
import { text, blue } from '../components/common.module.css'

// markup
const NotFoundPage = () => {
  return (
    <div style={{  maxwidth:"100%", overflow: "hidden"}}>
      <Layout pageTitle="Audit Report Generator">
          <div className={`${text} ${blue}`} style={{ fontSize: "24px", margin: "130px", textAlign: "center"}}>
            OOPS!<br/><br/>
            Seems like you are lost!
          </div>
      </Layout>
    </div>
  )
}

export default NotFoundPage
